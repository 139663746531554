<template>
  <Toolbar>
    <template #left>
      <label class="p-field _base-search-size">
        <div class="p-input-icon-right">
          <InputText type="search" v-model="search" :placeholder="$t('search')" />
          <Icon name="zoom" className="p-field-icon" />
        </div>
      </label>
    </template>

    <template #right>
      <router-link :to="{ name: 'CreatingCompany' }" class="p-button mb-4  mt-5">
        <Icon name="plus" className="" />
        <span v-t="'company_page.add_new_company'" />
      </router-link>
    </template>
  </Toolbar>

  <div class="_relative">
    <Loader isOverlay v-if="state.isLoading"/>

    <DataTable :value="state.data" :loading="state.isLoading" responsiveLayout="scroll">
      <template #empty>
        <Empty />
      </template>
      <Column header="id">
        <template #body="slotProps">{{slotProps.data.id}}</template>
      </Column>
      <Column :header="$t('company_page.company_name')">
        <template #body="slotProps"><router-link class="_link" :to="{ name: 'Company', params: { companyID: slotProps.data.id } }">{{slotProps.data.title}}</router-link></template>
      </Column>
      <Column :header="$t('company_page.contact_person')">
        <template #body="slotProps">{{slotProps.data.chief.name}}</template>
      </Column>
      <Column :header="$t('company_page.phone')">
        <template #body="slotProps">{{slotProps.data.chief.phone}}</template>
      </Column>
      <Column :header="$t('company_page.credits')">
        <template #body="slotProps">{{slotProps.data.chief.credits}}</template>
      </Column>
      <Column :header="$t('company_page.employees')">
        <template #body="slotProps">{{slotProps.data.chief.employees}}</template>
      </Column>
      <Column :header="$t('status')">
        <template #body="slotProps"><StatusBool :isActive="slotProps.data.is_active" /></template>
      </Column>
    </DataTable>
  </div>
  <Paginator
    :currentPage="state.meta.current_page || $route.query.page || 1"
    :total="state.meta.total"
    :lastPage="state.meta.last_page"
    :perPage="state.meta.perPage"
    :count="state.meta.to"
  />
</template>

<script>

import { ref, watch } from 'vue';
import { useStore } from 'vuex'
import Toolbar from 'primevue/toolbar';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useRoute, useRouter } from 'vue-router'

import { Icon, Empty, Loader, StatusBool } from '../../components/ui'
import { Paginator } from '../../components/common'

export default {
  components: {
    Toolbar,
    InputText,
    DataTable,
    Column,
    Icon,
    Empty,
    Loader,
    StatusBool,
    Paginator,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const search = ref()
    const state = store.state.companies

    store.dispatch('companies/reqBase', { per_page: 15, page: route.query.page, search: search.value  })

    watch(route, () => {
      store.dispatch('companies/reqBase', { per_page: 15, page: route.query.page, search: search.value })
    })

    watch(search, (newValue) => {
      // const query = {...route.query, ...{ search: newValue }}
      router.push({ name: route.name, query: { search: newValue } })
    })

    return {
      state,
      search,
    }
  }
}
</script>
